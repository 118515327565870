import { useEffect, useRef, useState } from 'react';
import './App.css';
import logo from './nicklenij.svg';
import ex from './heuristic_example.png';
import pim from './pim_paper.png';
import { useHash } from 'react-use';

const Application = () => {
  const [hash, setHash] = useHash();
  const popoverContent = useRef<HTMLDivElement>(null);
  const [clickedItem, setClickedItem] = useState('');

  useEffect(() => {
    setClickedItem(hash.substring(1));
    setTimeout(() => {
      if (hash) {
        window.scrollTo({
          top: popoverContent.current?.offsetTop,
          behavior: 'smooth',
        });
      }
    }, 0);
  }, [hash]);

  useEffect(() => {
    setTimeout(() => {
      if (!hash) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }, 0);
  });

  const getPopoverContent = () => {
    return (
      <div className="clicked-content">
        <div className="content-title">
          {clickedItem === 'UX' ? 'User experience' : clickedItem}
          {clickedItem && (
            <a
              className="mail-button"
              href="mailto:hi@nicklenij.com?subject=Interest in offerings&body=Hi!%0D%0A%0D%0AI'm interested in one of the offerings listed on nicklenij.com.%0D%0A%0D%0AThanks!"
              target="_blank"
              rel="noreferrer"
            >
              <span
                style={{ fontSize: '40px' }}
                className="material-symbols-outlined"
              >
                send
              </span>
            </a>
          )}
        </div>
        {clickedItem === 'UX' && (
          <div>
            I've got a few tricks up my sleeve for reviewing your app's user
            experience:
            <br />
            <br />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span
                style={{ fontSize: '40px', marginRight: '20px' }}
                className="material-symbols-outlined"
              >
                tune
              </span>
              <div>
                <i>Heuristic evaluations</i>
                <br />
                Dive into the app's overall UX and receive a holistic account of
                its market-readiness based on design and interaction norms
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <span
                style={{
                  fontSize: '40px',
                  marginRight: '20px',
                }}
                className="material-symbols-outlined"
              >
                group
              </span>
              <div>
                <i>Personas & user flows</i>
                <br />
                Get a deeper understanding of your users' goals, workflows, and
                potential usage patterns
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <span
                style={{
                  fontSize: '40px',
                  marginRight: '20px',
                }}
                className="material-symbols-outlined"
              >
                design_services
              </span>
              <div>
                <i>Interaction & prototyping</i>
                <br />
                Test out scenarios with clickable prototyping in industry
                standard Figma mockups
              </div>
            </div>
            <div
              style={{
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              The proof is in the pudding - check out some examples:
            </div>
            <div style={{ display: 'flex', flexFlow: 'column' }}>
              <a href="/heuristic_eval_example.pdf" target="_blank">
                <img className="example" alt="logo" src={ex} />
              </a>
            </div>
          </div>
        )}
        {clickedItem === 'Research' && (
          <div>
            I have experience in user research and academic research through my
            ongoing work toward an MS in Human Centered Design & Engineering.
            I'm happy to help with either!
            <br />
            <br />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span
                style={{ fontSize: '40px', marginRight: '20px' }}
                className="material-symbols-outlined"
              >
                record_voice_over
              </span>
              <div>
                <i>User research</i>
                <br />
                • In-depth interviews
                <br />• Usability testing
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <span
                style={{
                  fontSize: '40px',
                  marginRight: '20px',
                }}
                className="material-symbols-outlined"
              >
                school
              </span>
              <div>
                <i>Academic research</i>
                <br />• Phenomenological interviews & focus groups
                <br />• HCI/HCDE theory (e.g., critical theory, grounded theory)
                <br />• Writing for academia
              </div>
            </div>
            <div
              style={{
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              Ready to read? Here are some examples:
            </div>
            <div style={{ display: 'flex', flexFlow: 'column' }}>
              <a
                href="http://pimworkshop.org/2022/docs/Moolenijzer_Suh_PS.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <img className="example" alt="logo" src={pim} />
              </a>
            </div>
          </div>
        )}
        {clickedItem === 'Development' && (
          <div>
            With over 8 years of experience, I'm well versed in a variety of
            software development technologies, primarily in the web space. The
            following tech stacks are what I prefer, but I'm delusionally
            confident I can learn anything!
            <br />
            <br />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span
                style={{ fontSize: '40px', marginRight: '20px' }}
                className="material-symbols-outlined"
              >
                web
              </span>
              <div>
                <i>Frontend</i>
                <br />
                • React
                <br />
                • Angular
                <br />• HTML / CSS / JS
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <span
                style={{
                  fontSize: '40px',
                  marginRight: '20px',
                }}
                className="material-symbols-outlined"
              >
                lan
              </span>
              <div>
                <i>Backend</i>
                <br />
                • Node
                <br />• Ruby (limited)
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <span
                style={{
                  fontSize: '40px',
                  marginRight: '20px',
                }}
                className="material-symbols-outlined"
              >
                dns
              </span>
              <div>
                <i>Hosting</i>
                <br />
                • Microsoft Azure
                <br />
                • Google Cloud
                <br />• Static hosting
              </div>
            </div>
            <div
              style={{
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              Professionally, I've worked on Microsoft's cutting edge cloud SaaS
              offerings in Azure & Spotify's enterprise podcast management
              software.
            </div>
            {/* <div style={{ display: 'flex', flexFlow: 'column' }}>
  <a href="/heuristic_eval_example.pdf" target="_blank">
    <img className="example" alt="logo" src={ex} />
  </a>
</div> */}
          </div>
        )}
        {clickedItem === '' && <></>}
      </div>
    );
  };

  return (
    <div className="main">
      <div className="content">
        <header className="title">
          <img alt="logo" src={logo} />
          {/* <div>/ nik • len - i /</div> */}
          {/* <div>/ nɪk • lɛnaɪ /</div> */}
        </header>
        <div className="row">Hi, how can I help?</div>
        <div ref={popoverContent} className="row">
          <div
            className={`icon-link ${clickedItem === 'UX' ? 'selected' : ''}`}
            onClick={() => setHash('UX')}
          >
            <span className="material-symbols-outlined">architecture</span>
            <div>UX</div>
          </div>
          <div
            className={`icon-link ${
              clickedItem === 'Research' ? 'selected' : ''
            }`}
            onClick={() => setHash('Research')}
          >
            <span className="material-symbols-outlined">history_edu</span>
            <div>Research</div>
          </div>
          <div
            className={`icon-link ${
              clickedItem === 'Development' ? 'selected' : ''
            }`}
            onClick={() => setHash('Development')}
          >
            <span className="material-symbols-outlined">terminal</span>
            <div>Development</div>
          </div>
        </div>
        {getPopoverContent()}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '30px',
          marginBottom: '15px',
          flexFlow: 'column',
          fontSize: '14px',
        }}
      >
        <div>:)</div>
        <div>©2023 nick lenij</div>
      </div>
    </div>
  );
};

export default Application;
